import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CCol,
  CRow,
  CButton,
} from "@coreui/react";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs";
import { CiCircleCheck } from "react-icons/ci";
import GetTestStatus from "../GetTestStatus";
import { useMediaQuery } from "react-responsive";

// Define the types for the props
interface LatestDiagnostic {
  old: boolean;
  dated: string;
}

type HandleYesClick = () => void;

interface DiagnosticProps {
  latestDiagnostic: LatestDiagnostic;
  handleYesClick: HandleYesClick;
}

const Diagnostic: React.FC<DiagnosticProps> = ({
  latestDiagnostic,
  handleYesClick,
}) => {
  const isTablet = useMediaQuery({ maxWidth: 714, maxHeight: 1200 });
	const isTabletLandscape = useMediaQuery({ maxWidth: 1200, maxHeight: 714, orientation: 'landscape' });
  const [buttonColor, setButtonColor] = useState("#D8D8D8");
  const [textColor, setTextColor] = useState("#000000");

  useEffect(() => {
    // Move helper functions inside useEffect
    const getButtonColor = () => {
      if (latestDiagnostic?.dated === "--/--/--" && latestDiagnostic?.old === null) {
        return "#D8D8D8";
      } else if (latestDiagnostic?.dated === "--/--/--" && latestDiagnostic?.old === true) {
        return "#FF0000";
      }
      return latestDiagnostic?.old ? "#FF0000" : "#1488f5";
    };

    const getTextColor = () => {
      if (latestDiagnostic?.dated === "--/--/--" && latestDiagnostic?.old === null) {
        return "#000000";
      }
      return "#FFFFFF";
    };

    const newColor = getButtonColor();
    const newTextColor = getTextColor();
    setButtonColor(newColor);
    setTextColor(newTextColor);
  }, [latestDiagnostic]);

  // Helper function to determine button text
  const getButtonText = () => {
    if (latestDiagnostic?.dated === "--/--/--") {
      return "Take a diagnostic";
    }
    return !latestDiagnostic.old ? "Completed" : "Take a diagnostic";
  };


  return (
    <CCard style={{
      backgroundColor: "#DAEEF5",
      ...(isTabletLandscape ? {
        maxWidth: '200px',
        height: '280px',
        marginTop: '-0.5rem'
      } : isTablet ? {
        maxWidth: '220px',
        height: '280px',
        marginTop: '1rem'
      } : {})
    }}>
      <CCardBody>
        <CCardTitle>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: isTablet ? "60px" : isTabletLandscape ? "70px" : "150px",
          }}>
            <BiSearchAlt2
              style={{
                color: "#1488f5",
                ...(isTabletLandscape ? {
                  margin: '-20px 0'
                } : {})
              }}
              size={isTablet ? "40" : isTabletLandscape ? "60" : "100"}
            />
          </div>
          <h4 style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isTabletLandscape ? {
              fontSize: '16px',
              margin: '0.5rem 0'
            } : isTablet ? {
              fontSize: '14px',
              margin: '1rem 0'
            } : {})
          }}>
            Diagnostic
          </h4>
        </CCardTitle>

        <CCardText>
          <CRow style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isTablet ? {
              marginTop: '5px'
            } : {})
          }}>
            <CCol style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: isTablet ? "1.2rem" : "2.8rem",
            }}>
              <CRow>
                <small style={{
                  color: "grey",
                  ...(isTablet ? {
                    fontSize: '10px',
                    marginTop: '1rem'
                  } : isTabletLandscape ? {
                    fontSize: '10px'
                  } : {})
                }}>Status</small>
              </CRow>

              <CRow>
                <small style={{
                  fontFamily: "Expansiva, sans-serif",
                  display: "flex",
                  flexDirection: "row",
                  ...(isTablet || isTabletLandscape ? {
                    fontSize: '10px'
                  } : {})
                }}>
                  <GetTestStatus test={latestDiagnostic?.old === false} />
                  {latestDiagnostic?.old === false ? "Active" : "Inactive"}
                </small>
              </CRow>
            </CCol>
            <CCol style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: isTablet ? "1.2rem" : "2.8rem",
            }}>
              <CRow>
                <small style={{
                  color: "grey",
                  ...(isTablet ? {
                    fontSize: '10px',
                    marginTop: '1rem'
                  } : isTabletLandscape ? {
                    fontSize: '10px'
                  } : {})
                }}>Previous Date</small>
              </CRow>

              <CRow>
                <small style={{
                  fontFamily: "Expansiva, sans-serif",
                  fontSize: isTablet || isTabletLandscape ? "8px" : "0.7rem",
                }}>
                  {latestDiagnostic?.dated.replace(/-/g, ".").slice(0, 10)}
                </small>
              </CRow>
            </CCol>
          </CRow>
        </CCardText>
        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          ...(isTablet && {
            marginTop: '5px'
          })
        }}>
          <CButton
            className="centeralized no-margin"
            style={{
              backgroundColor: buttonColor,
              color: textColor,
              ...(isTablet && {
                padding: '5px 10px',
                fontSize: '12px'
              })
            }}
            color="primary"
            onClick={handleYesClick}
            title={latestDiagnostic?.old ? "Click to run system diagnostic" : ""}
          >
            {latestDiagnostic?.old ? (
              <BsExclamationCircle style={{
                marginRight: isTablet ? "3px" : "5px",
                width: isTablet ? "12px" : "20px",
                height: isTablet ? "12px" : "20px"
              }} />
            ) : (
              <CiCircleCheck
                data-testid={"ci-circle-check-icon"}
                style={{
                  marginRight: isTablet ? "3px" : "5px",
                  width: isTablet ? "12px" : "20px",
                  height: isTablet ? "12px" : "20px"
                }}
              />
            )}
            {getButtonText()}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default Diagnostic;
