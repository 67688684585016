import React, { useState, useEffect } from "react";
import GetTestStatus from "../GetTestStatus";
import { CiCircleCheck } from "react-icons/ci";
import { MdOutlineSpeed } from "react-icons/md";
import { BsExclamationCircle } from "react-icons/bs";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CCol,
  CRow,
  CButton,
} from "@coreui/react";
import { useMediaQuery } from "react-responsive";

interface LatestUnitCalibration {
	old: boolean;
	dated: string;
}

type HandleUnitCalibration = () => Promise<void>;

interface CalibrationProps {
	latestUnitCalibration: LatestUnitCalibration;
	handleUnitCalibration: HandleUnitCalibration;
}

const Calibration: React.FC<CalibrationProps> = ({
  latestUnitCalibration,
  handleUnitCalibration,
}) => {
  const isTablet = useMediaQuery({ maxWidth: 714, maxHeight: 1200 });
	const isTabletLandscape = useMediaQuery({ maxWidth: 1200, maxHeight: 714, orientation: 'landscape' });
  const [buttonColor, setButtonColor] = useState("#D8D8D8");
  const [textColor, setTextColor] = useState("#000000");

  useEffect(() => {
    // Move helper functions inside useEffect to avoid dependency issues
    const getButtonColor = () => {
      if (latestUnitCalibration?.dated === "--/--/--" && latestUnitCalibration?.old === null) {
        return "#D8D8D8";
      } else if (latestUnitCalibration?.dated === "--/--/--" && latestUnitCalibration?.old === true) {
        return "#FF0000";
      }
      return latestUnitCalibration?.old ? "#FF0000" : "#1488f5";
    };

    const getTextColor = () => {
      if (latestUnitCalibration?.dated === "--/--/--" && latestUnitCalibration?.old === null) {
        return "#000000";
      }
      return "#FFFFFF";
    };

    const newColor = getButtonColor();
    const newTextColor = getTextColor();
    setButtonColor(newColor);
    setTextColor(newTextColor);
  }, [latestUnitCalibration]);

  // Helper function to determine button text
  const getButtonText = () => {
    if (latestUnitCalibration?.dated === "--/--/--") {
      return "Calibrate Unit";
    }
    return !latestUnitCalibration.old ? "Completed" : "Calibrate Unit";
  };

  return (
    <CCard style={{
      backgroundColor: "#DAEEF5",
      ...(isTabletLandscape ? {
        maxWidth: '200px',
        height: '280px',
        marginTop: '-0.5rem'
      } : isTablet ? {
        maxWidth: '220px',
        height: '280px',
        marginTop: '1rem'
      } : {})
    }}>
      <CCardBody>
        <CCardTitle>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: isTablet ? "60px" : isTabletLandscape ? "70px" : "150px",
          }}>
            <MdOutlineSpeed
              style={{
                color: "#1488f5",
                ...(isTabletLandscape ? {
                  margin: '-20px 0'
                } : {})
              }}
              size={isTablet ? "40" : isTabletLandscape ? "60" : "100"}
            />
          </div>
          <h4 style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isTabletLandscape ? {
              fontSize: '16px',
              margin: '0.5rem 0'
            } : isTablet ? {
              fontSize: '14px',
              margin: '1rem 0'
            } : {})
          }}>
            Calibration
          </h4>
        </CCardTitle>

        <CCardText>
          <CRow style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isTablet && {
              marginTop: '5px'
            })
          }}>
            <CCol style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: isTablet ? "1.2rem" : "2.8rem",
            }}>
              <CRow>
                <small style={{
                  color: "grey",
                  ...(isTablet ? {
                    fontSize: '10px',
                    marginTop: '1rem'
                  } : isTabletLandscape ? {
                    fontSize: '10px'
                  } : {})
                }}>Status</small>
              </CRow>

              <CRow>
                <small style={{
                  fontFamily: "Expansiva, sans-serif",
                  display: "flex",
                  flexDirection: "row",
                  ...(isTablet || isTabletLandscape ? {
                    fontSize: '10px'
                  } : {})
                }}>
                  <GetTestStatus test={latestUnitCalibration?.old === false} />
                  {latestUnitCalibration?.old === false ? "Active" : "Inactive"}
                </small>
              </CRow>
            </CCol>
            <CCol style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: isTablet ? "1.2rem" : "2.8rem",
            }}>
              <CRow>
                <small style={{
                  color: "grey",
                  ...(isTablet ? {
                    fontSize: '10px',
                    marginTop: '1rem'
                  } : isTabletLandscape ? {
                    fontSize: '10px'
                  } : {})
                }}>Previous Date</small>
              </CRow>

              <CRow>
                <small style={{
                  fontFamily: "Expansiva, sans-serif",
                  fontSize: isTablet || isTabletLandscape ? "8px" : "0.7rem",
                }}>
                  {latestUnitCalibration?.dated.replace(/-/g, ".").slice(0, 10)}
                </small>
              </CRow>
            </CCol>
          </CRow>
        </CCardText>
        <div style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          ...(isTablet || isTabletLandscape ? {
            marginTop: '5px'
          } : {})
        }}>
          <CButton
            className="centeralized no-margin"
            style={{
              backgroundColor: buttonColor,
              color: textColor,
              ...(isTablet || isTabletLandscape ? {
                padding: '5px 10px',
                fontSize: '13px'
              } : {})
            }}
            color="primary"
            onClick={handleUnitCalibration}
            title={latestUnitCalibration?.old ? "Click to run now or wait for the scheduled time" : ""}
          >
            {latestUnitCalibration?.dated === "--/--/--" ? (
              <BsExclamationCircle style={{
                marginRight: isTablet || isTabletLandscape ? "3px" : "5px",
                width: isTablet || isTabletLandscape ? "12px" : "20px",
                height: isTablet || isTabletLandscape ? "12px" : "20px"
              }} />
            ) : !latestUnitCalibration?.old ? (
              <CiCircleCheck
                data-testid={"ci-circle-check-icon"}
                style={{
                  marginRight: isTablet || isTabletLandscape ? "3px" : "5px",
                  width: isTablet || isTabletLandscape ? "12px" : "20px",
                  height: isTablet || isTabletLandscape ? "12px" : "20px"
                }}
              />
            ) : (
              <BsExclamationCircle style={{
                marginRight: isTablet || isTabletLandscape ? "3px" : "5px",
                width: isTablet || isTabletLandscape ? "12px" : "20px",
                height: isTablet || isTabletLandscape ? "12px" : "20px"
              }} />
            )}
            {getButtonText()}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
  );
};

export default Calibration;
