import React, { createContext, useState } from 'react';

const initialFields = [
    { id: 1, content: 'Strain Name (Text Field)', locked: true, type: 'Text' },
    { id: 2, content: 'Pressure (psi) (Number Field)', locked: true, type: 'Number' },
    { id: 3, content: 'Temperature (°C) (Number Field, supports negatives)', locked: true, type: 'Number' },
    { id: 4, content: 'Input Weight (Lbs) (Number Field)', locked: false, type: 'Number' },
    { id: 5, content: 'Operator (Dropdown)', locked: true, type: 'Dropdown' },
    { id: 6, content: 'Pressure 2 (psi) (Number Field)', locked: false, type: 'Number' },
    { id: 7, content: 'Temperature 2 (°C) (Number Field, supports negatives)', locked: false, type: 'Number' },
  ];

const defaultFields = [
    { id: 4, content: 'Input Weight (Lbs) (Number Field)', locked: false, type: 'Number' },
    { id: 6, content: 'Pressure 2 (psi) (Number Field)', locked: false, type: 'Number' },
    { id: 7, content: 'Temperature 2 (°C) (Number Field, supports negatives)', locked: false, type: 'Number' },
];

const initialSelectedFields = [
    { id: 1, content: 'Strain Name (Text Field)', locked: true, type: 'Text' },
    { id: 2, content: 'Pressure (psi) (Number Field)', locked: true, type: 'Number' },
    { id: 3, content: 'Temperature (°C) (Number Field, supports negatives)', locked: true, type: 'Number' },
    { id: 5, content: 'Operator (Dropdown)', locked: true, type: 'Dropdown' },
];

export const FieldsContext = createContext<any>({
    initialFields: initialFields,
    setSelectedFields: () => {},
});

export const FieldsProvider = ({ children }: { children: React.ReactNode }) => {
    const [fields, setFields] = useState<any>(defaultFields);
    const [selectedFields, setSelectedFields] = useState<any>(initialSelectedFields);

    return (
        <FieldsContext.Provider
            value={{
                fields,
                setFields,
                selectedFields,
                setSelectedFields,
                initialFields,
                defaultFields,
                initialSelectedFields
            }}
        >
            {children}
        </FieldsContext.Provider>
    );
};
