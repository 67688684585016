import React, {ReactNode} from "react";
import jwt_decode from 'jwt-decode';
import {useNavigate} from "react-router-dom";
import { createContext, useState } from 'react';
import { Login } from './context-provider/login';
import { Logout } from './context-provider/logout';
import { Register } from './context-provider/register';

interface AuthContextType {
    user: User | null;
    setUser?: React.Dispatch<React.SetStateAction<User | null>>;
    login: (payload: any) => Promise<void>;
    logout: () => Promise<void>;
    register: (payload: any) => Promise<void>;
    UserName: string;
    setUserName: React.Dispatch<React.SetStateAction<string>>;
}

interface User {
    id: number;
    name: string;
    email: string;
    isAdmin: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {

    const navigate = useNavigate();
    const [UserName, setUserName] = useState('User Name');
    const [user, setUser] = useState<User | null>(() => {
        if (localStorage.getItem('tokens')) {
            let tokens = JSON.parse(localStorage.getItem('tokens') || '');
            return jwt_decode(tokens.token.access);
        }
        return null;
    });

    // logout a user
    const logout = async () => {
        Logout(setUser, navigate);
        localStorage.removeItem('onboardingStatus');
        localStorage.removeItem('systemHealth');
        localStorage.removeItem('tokens');
    };

    // login a user
    const login = async (payload: any) => {
        await Login(payload, setUser, navigate);
    };

    // register a user
    const register = async (payload: any) => {
        await Register(payload, setUser, navigate);
    }


    return (
        <AuthContext.Provider value={{ user, login, logout, register, UserName, setUserName }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
