import React, { useState } from "react";
import {
  CModal,
  CButton,
  CForm,
  CFormLabel,
  CFormSelect,
  CFormInput
} from '@coreui/react';
import { useMediaQuery } from "react-responsive";

interface ScheduleAutomationModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (time: string, interval: string) => void;
}

interface FormErrors {
  time?: string;
  interval?: string;
}

const ScheduleAutomationModal: React.FC<ScheduleAutomationModalProps> = ({
  show,
  onClose,
  onSave,
}) => {
  const [time, setTime] = useState<string>("");
  const [interval, setInterval] = useState<string>("");
  const [errors, setErrors] = useState<FormErrors>({});
  const isTablet = useMediaQuery({ maxWidth: 992 });

  const handleSave = () => {
    if (!time) {
      // alert("Please set both time and interval.");
      setErrors((prevErrors) => ({
        ...prevErrors,
        time: "Please set the time",
      }));
      // return;
    }
    if (!interval) {
      // alert("Please set both time and interval.");
      setErrors((prevErrors) => ({
        ...prevErrors,
        interval: "Please set the interval",
      }));
      // return;
    }
    if (time || interval) {
      onSave(time, interval);
    }
  };

  return (
    <CModal visible={show} onClose={onClose} alignment="center">
      <header className="p-4">
        <h5>Set Automation Schedule</h5>
      </header>
      <main className="p-4" style={{ marginTop: '-30px' }}>
        <CForm>
          <div className="mb-3">
            <CFormLabel htmlFor="automationTime">Time</CFormLabel>
            <CFormInput
              type="time"
              id="automationTime"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
              style={{
                ...(isTablet && {
                  height: '35px',
                  fontSize: '14px'
                })
              }}
            />
            {errors?.time && (
              <p className="error">{errors.time}</p>
            )}
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="automationInterval">Interval</CFormLabel>
            <CFormSelect
              id="automationInterval"
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              required
              style={{
                ...(isTablet && {
                  height: '35px',
                  width: '60%',
                  fontSize: '14px'
                })
              }}
            >
              <option value="" style={isTablet ? { lineHeight: '1.2', fontSize: '10px' } : {}}>Select an interval</option>
              <option value="daily" style={isTablet ? { lineHeight: '1.2', fontSize: '10px' } : {}}>Daily</option>
            </CFormSelect>
            {errors?.interval && (
              <p className="error">{errors.interval}</p>
            )}
          </div>
        </CForm>
      </main>
      <footer className="d-flex justify-content-center p-4" style={{ marginTop: '-30px' }}>
        <CButton color="primary" onClick={handleSave}>
          Save
        </CButton>
      </footer>
    </CModal>
  );
};

export default ScheduleAutomationModal;
