import React, { useState } from 'react';
import { CContainer, CProgress, CButton } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import AddTeamMembers from './Steps/AddTeamMembers/index';
import ConnectHardware from './Steps/ConnectHardware';
import CustomizeExperience from './Steps/CustomizeExperience';
import './onboarding.css';

interface StepStatus {
  id: number;
  name: string;
  completed: boolean;
}

const Onboarding: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [stepStatuses, setStepStatuses] = useState<StepStatus[]>(() => {
    const saved = localStorage.getItem('onboardingStatus');
    if (saved) {
      return JSON.parse(saved);
    }
    return [
      { id: 1, name: 'Team Members', completed: false },
      { id: 2, name: 'Hardware Setup', completed: false },
      { id: 3, name: 'Customize', completed: false },
    ];
  });

  const completedSteps = stepStatuses.filter(step => step.completed).length;
  const totalSteps = stepStatuses.length;
  const progress = (completedSteps / totalSteps) * 100;

  const handleStepComplete = (stepNumber: number) => {
    const updatedStatuses = stepStatuses.map(step =>
      step.id === stepNumber ? { ...step, completed: true } : step
    );
    setStepStatuses(updatedStatuses);
    localStorage.setItem('onboardingStatus', JSON.stringify(updatedStatuses));
    setCurrentStep(stepNumber + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getStepStatus = (stepNumber: number) => {
    const step = stepStatuses.find(s => s.id === stepNumber);
    if (step?.completed) return 'completed';
    if (currentStep === stepNumber) return 'current';
    return 'pending';
  };

  const renderStepIndicators = () => {
    return (
      <div className="step-indicators">
        {stepStatuses.map((step) => (
          <div
            key={step.id}
            className={`step-indicator ${getStepStatus(step.id)}`}
            onClick={() => setCurrentStep(step.id)}
          >
            <div className="step-number">{step.id}</div>
            <div className="step-label">{step.name}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <AddTeamMembers
            onComplete={() => handleStepComplete(1)}
            onSkip={() => setCurrentStep(2)}
          />
        );
      case 2:
        return (
          <ConnectHardware
            onComplete={() => handleStepComplete(2)}
            onSkip={() => setCurrentStep(3)}
          />
        );
      case 3:
        return (
          <CustomizeExperience
            onComplete={() => handleStepComplete(3)}
            onSkip={() => navigate('/ExtractoPredictionDashboard')}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CContainer className="onboarding-container">
      <div className="onboarding-header">
        <h1>Welcome to Newton Insights! Let&apos;s Get Started.</h1>
        <p>Don&apos;t worry these steps can be skipped and revisited later</p>
      </div>

      {renderStepIndicators()}

      <div className="progress-section">
        <CProgress
          className="onboarding-progress"
          value={progress}
          height={8}
        />
        <div className="step-indicator">
          Step {currentStep} of {totalSteps}
        </div>
      </div>

      <div className="onboarding-content">
        {renderCurrentStep()}
      </div>

      <div className="step-navigation">
        {currentStep > 1 && (
          <CButton
            color="primary"
            variant="outline"
            onClick={handlePreviousStep}
          >
            Previous Step
          </CButton>
        )}
      </div>
    </CContainer>
  );
};

export default Onboarding;
